.virtualPhone {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    color: white;
    font-size: 2vh;
    position: relative;
}

.phone_call_type {
    height: 48px;
}

.virtualPhoneHeader {
    background-color: var(--primaryColour);
    padding: 2px;
}

.virtualPhoneMain {
    flex: 1;
}

.virtualPhoneFooter {
    background-color: var(--primaryColour);
    padding: 2px;
}

.phone_call_container {
    display: flex;
    flex-direction: row;
    padding: 2px;
}

.phone_calltype{ font-size: 2vh; }

.phone_call_body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5vh;
}

.phone_timer{
    font-size: 2.5vh;
}

.phone_content {
    flex: 1;
}

.phone_img {
    width: 230px; /* You can adjust the width to fit your mockup design */
    height: 340px;
    position: absolute;
    top: 25px;
    left: 25px;
}

.careclicks_modal{
    background-color: white;
    position: absolute; 
    top: 10px; 
    left: 10px;
    height: 250px;
    width: 90%;
    color: black;
    z-index: 10;
    padding: 5px;
    border: 1px solid black;
    border-radius: 10px;
    text-align: -webkit-center;
}

.careclick_icon {
    height: 35px;
    width: 35px;
    margin: 3px;
}

.careclick_card {
    width: 30%;
    max-height: 100px;
    overflow: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 9px;
    align-items: center;
}
.careclicks_modal_title {
    font-size: 11px;
    padding: 1px;
}

.careclicks_select_container {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
}
.careclicks_row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}