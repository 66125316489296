.journeybar_main_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
}

.journeybar_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
    height: 100%;
    border-radius: 9999px;
    height: 0.5rem;
}

.journeybar_care_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
    flex: 1 1;
    height: 100%;
    border-radius: 9999px;
    height: 0.5rem;
}

.journeybar_emg{

    background-color: red;
}

.journeybar_att{
    background-color: green;
}

.journeybar_acc{
    background-color: purple;
}

.journeybar_cll{
    background-color: orange;
}

.journeybar_ass{
    background-color: yellow;
}

.kpi_marker_outside{
    width: 1.25rem;
    /*background-color: blue;*/
    position: absolute;
}

.kpi_marker_inside {
    background-color: blue;
    box-shadow: 0px 0 0 #0000;
}


.journey_bar_section_tooltip {
    height: 200px;
    width: 200px;
    background-color: white;
}