/* Create a common style for the phone frame */
.phone {
    width: 230px; /* You can adjust the width to fit your mockup design */
    height: 340px; /* You can adjust the height to fit your mockup design */
    border: 10px solid black;
    border-top: 40px solid black;
    border-bottom: 20px solid black;
    margin: 5px; /* Add some space between the two phone mockups */
    display: inline-block;
    border-radius: 20px;
    overflow: hidden;
}

/* Landscape orientation */
.phone.landscape {
    /*transform: rotate(90deg);*/
    /*
    width: 540px; 
    height: 360px; 
    */
    width: 400px; 
    height: 300px; 
    
}

/* Portrait orientation */
.phone.portrait {
    /* You can style the portrait orientation if needed */
    align-self: center;
}

img.room_unit { height: 200px; }

.sense_status_selected {
    background-color: purple;
    padding: 5px;
    color:white; 
    border-radius: 6px;
}

.sense_status_deselected {
    background-color: none;
}

.btn_style {
    padding: 3px;
    border-radius: 6px;
    margin: 2px;
}