:root {
  --primaryColour: #4cc1bd;
  --lighthighlightColour: white;
  --chartTitleColour: grey;
  --chartSubTitleColour: grey;
  --chartTickColour: grey;
  --chartTickColour: grey;
  --secondaryColour: #dcf5f3;
  --chartBarTitleColour: grey;
  --detailsTextColour: grey;
  --bodyBGColour: white;
  --headerBGColour: white;
  --chartBGColour: lightgray;

  /* call colours */
  --emergency : red;
  --call : rgb(245, 129, 78);
  --sense : #914397;
  --attendance : rgb(148, 202, 102);
  --accessory : #914397;
  --assistance : #F8DA3C;
  --carecall : rgb(225, 21, 131);
  --visit: rgb(1, 87, 62);
  --all: #4cc1bd;

}

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
 
html, body {
  font-family: "Muli",sans-serif!important;
}
  
.App {
  text-align: center;
}

.header_logo {
  width: 135px;
  padding: 5px;
}

.login_logo {
  width: 47vh;
  height: 18vh;
  padding: 5px;
}

th::first-letter {
    text-transform:capitalize;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  */
  font-size: calc(10px + 2vmin);
  background-color:#4cc1bd; 
  /*
  display: flex;
  flex-direction: row;
  */
  color:  #dcf5f3;
  
  /*
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  flex: 0 0 53px;
  width: 100%;
  top: 0px;
*/
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg_emergency { background-color: var(--emergency); color: var(--headerBGColour);}
.bg_call { background-color: var(--call); color: var(--headerBGColour); }
.bg_assistance { background-color: var(--assistance); color: var(--headerBGColour); }
.bg_attendance { background-color: var(--attendance); color: var(--headerBGColour); }
.bg_sense, .bg_accessory { background-color: var(--accessory); color: var(--headerBGColour); }
.bg_carecall { background-color: var(--carecall); color: var(--headerBGColour); }
.bg_all { background-color: var(--all); color: var(--headerBGColour); }

.colour-white{ color: white}

img.live_dashboard_call_type {
  width: 30px;
  height: 30px;
}

img.sense_dashboard {
  width: 50px;
  height: 50px;

}

/* DASHBOARD CALLS */
.dashboard_calls_header {
  /*
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  */
  width: 100%;
  color: white;
  display: grid;
  grid-template-columns: 30px 130px 60px 60px;
  grid-gap: 5px;
}

.dashboard_calls_header divs {
  text-align: left;
}

/* LIVE CALLS */
.live_call_header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.call_body_field_header{
  display: grid;
  grid-template-columns: 100px auto;
  grid-gap: 5px;
}

.call_body_field_header div {color: white;}

.live_call_header_container div { color: white; }

/* SENSE CALLS */
.sense_call_header_container {
  display: grid;
  grid-template-columns: 30px auto;
  grid-gap: 5px;
  width: 100%;
}

.sense_call_header_container div { color: white; }

.dashboard_frame {
  /*height: 52vh;*/
  overflow-y: auto;
}

.left-padding-10 {padding-left: 10px;}
.align-left { text-align: left; }

.upper_case { text-transform: uppercase; }

.davy_monitor {
  display: grid;
  grid-template-columns: 150px 150px 350px 150px;
  grid-gap: 5px;
  width: 100%;
}


.bg_sense_fall {
  background-color: #ED0B44;
}

.bg_sense_manOnGround {
  background-color: #DA1654;
}


.bg_sense_bathroom {
  background-color: #C82265;
}

.bg_sense_chair {
  background-color: #B62D76;
}

.bg_sense_bed {
  background-color: #A33886;
}

.bg_sense_room {
  background-color: #914397;
}

.header_logo_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 3.5vh;
  font-weight: 500;
}
.header_logo_container div {
  font-weight: 100;
  font-size: 3vh;
  text-transform: uppercase;
  font-weight: 100;
  top: 3px;
  position: relative;
}

.live_call_container {
  overflow: visible;
}

.uppercasefirst::first-letter {
  text-transform: uppercase;
}


.main_demo_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
}

.split_handler{
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.split_card_top {
  height:320px;

}

.split_card_bottom {
  height:320px;
}


.secondRowPanel {
  display: grid;
  grid-template-columns: 95px 100px 100px 20px;
  gap: 10px; /* Adjust the gap as needed */
  align-items: center;
  width: 100%;
}

.secondRowPanelItem {
  border: 1px solid #ccc; /* Add border for visualization */
  padding: 10px; /* Add padding as needed */
}


@import url("./css/buttons/button_3.css");
@import url("./css/display/flex.css");
@import url("./css/display/position.css");
@import url("./css/display/grid.css");
@import url("./css/pages/integration.css");
@import url("./css/components/card.css");








